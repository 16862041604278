import { type Place } from '../../../model/Place';
import { getTheme } from '../../../utils/getTheme';
import { type PolicyWizardFormsData } from '../context/types';
import { useMappedFormsErrors, usePolicyWizardContext } from './context-hooks';

const isWtw = getTheme() === 'wtw';

const useAreRequiredFieldsNotEmpty = () => {
  const {
    configuration,
    forms: { data },
  } = usePolicyWizardContext();

  const requiredValues = [
    data?.customer?.company_name,
    configuration?.isAddressRequired
      ? (data?.customer?.address as Place)?.provider_place_uuid
      : true,
    configuration?.isShipmentIdRequired ? data?.externalReference : true,
    configuration?.isContainerNumbersRequired ? data?.containerIds : true,
    data?.etd,
    configuration?.isEuFreightForwarder ? data?.customer?.email : true,
  ];

  if (!isWtw) {
    requiredValues.push(data?.eta);
  }

  return requiredValues.every((v) => !!v);
};

/**
 * An entry point hook for exposing Shipment information form aggregated data
 * to consumer components.
 */
export const useShipmentInformationForm = () => {
  const { configuration, forms } = usePolicyWizardContext();
  const areRequiredFieldsFilled = useAreRequiredFieldsNotEmpty();
  const errorsMap = useMappedFormsErrors();

  const { data } = forms;
  const isValid = areRequiredFieldsFilled && forms.errors.length === 0;

  return {
    data: data as PolicyWizardFormsData,
    configuration,
    errors: errorsMap,
    isValid,
  };
};
