import { type FocusEvent } from 'react';
import { useParams } from 'react-router-dom';
import { type FieldApi, type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';
import moment from 'moment';
import invariant from 'tiny-invariant';

import { useUser } from '../../../context/auth/auth-hooks';
import { type Location } from '../../../network/apis/locations/types';
import {
  getJourneyUpdatePayload,
  isLocation,
} from '../../components/RouteDetails/utils';
import { getUpdatedValue } from '../../utils/forms';
import { getPortSearchDisplayValue } from '../../utils/ports';
import {
  type CargoOwner,
  type CertificateCreationPayload,
  type CertificatesRouteParams,
  type CertificateUpdatePayload,
  type MapsApiPayload,
} from '../types';
import { type CertificateFormProps } from './Form/types';
import { isPlaceSuggestion } from './utils';

export const useFormLogic = () => {
  const { policyId, certificateId } = useParams<CertificatesRouteParams>();

  const user = useUser();
  const isUpdate = window.location.pathname.includes('update');
  const isDuplicate = window.location.pathname.includes('duplicate');

  type LocationKeys =
    | 'origin'
    | 'destination'
    | 'placeOfLoading'
    | 'placeOfDelivery'
    | 'assuredAddress';

  // TODO: remove nested ifs - feels like this can be simplified
  const locationHandleBlur = <TName extends LocationKeys>(
    e: FocusEvent<HTMLInputElement, Element>,
    field: FieldApi<
      CertificateFormProps,
      TName,
      undefined,
      typeof zodValidator
    >,
    setter?: (value: string) => void,
  ) => {
    field.handleBlur();

    const value = field.state.value;
    const prevValue = field.prevState.value;

    if (!value || !prevValue) return;

    if (isPlaceSuggestion(value) && isPlaceSuggestion(prevValue)) {
      if (!e.target.value || value.place_id === prevValue.place_id) {
        field.setValue(prevValue);
        setter && setter(prevValue.description || '');
      }
    }

    if (isLocation(value) && isLocation(prevValue)) {
      if (value.type === 'port' && prevValue.type === 'port') {
        if (!e.target.value || value.port.code === prevValue.port.code) {
          field.setValue(prevValue);
          setter && setter(getPortSearchDisplayValue(prevValue.port) || '');
        }
      }

      if (value.type === 'place' && prevValue.type === 'place') {
        if (
          !e.target.value ||
          value.place.place_id === prevValue.place.place_id
        ) {
          field.setValue(prevValue);
          setter && setter(prevValue.place.description || '');
        }
      }
    }
  };

  const getPayload = (
    form: FormApi<CertificateFormProps, typeof zodValidator>,
  ): CertificateCreationPayload | CertificateUpdatePayload => {
    const data = form.state.values;
    const formMeta = form.state.fieldMeta;

    const getPlacePayload = (
      location?: Location | null,
    ): MapsApiPayload | null | undefined => {
      if (location === undefined) {
        return undefined;
      }
      if (location === null) {
        return null;
      }
      if (location.type === 'place') {
        return {
          place_id: location.place.place_id,
          session_token: location.place.session_token,
        };
      }

      return undefined;
    };

    const getAssuredDetails = (): CargoOwner | undefined | null => {
      // If an existing customer has been selected from the combobox, we need to send just the id
      if (data.assuredName?.id) {
        let provider_place_details = undefined;
        if (form.state.fieldMeta['assuredAddress'].isDirty) {
          // If the address has been updated and has been given a value, we need to send the new address for the existing customer, otherwise we send null (when the address has been removed or not updated) or undefined (when the address has not been updated)
          provider_place_details = data.assuredAddress?.description
            ? data.assuredAddress
            : null;
        }

        return {
          id: String(data.assuredName?.id),
          provider_place_details,
        };
      }

      // If a new customer has been entered, we need to send the name and address (no id)
      if (data.assuredName?.company_name && data.assuredAddress?.description) {
        return {
          name: data.assuredName?.company_name,
          provider_place_details: data.assuredAddress,
        };
      }

      // If a new customer has been entered without an address, we need to send the name (no id)
      if (data.assuredName?.company_name) {
        return {
          name: data.assuredName?.company_name,
        };
      }

      // If assuredName or assuredAddress has not been updated, we send nothing
      if (
        !form.state.fieldMeta['assuredName'].isDirty &&
        !form.state.fieldMeta['assuredAddress'].isDirty
      ) {
        return undefined;
      }

      return isUpdate ? null : undefined;
    };

    const loadingPlace = getPlacePayload(data.placeOfLoading);
    const deliveryPlace = getPlacePayload(data.placeOfDelivery);
    const originPlace = getPlacePayload(data.origin);
    const destinationPlace = getPlacePayload(data.destination);
    const originPortCode =
      data.origin?.type === 'port' ? data.origin.port.code : undefined;
    const destinationPortCode =
      data.destination?.type === 'port'
        ? data.destination.port.code
        : undefined;
    const getCreationPayload = (): CertificateCreationPayload => {
      if (
        !data.etd ||
        !data.cargoValue ||
        !data.cargoCurrency ||
        !user?.distributor.id ||
        !data.cargoDescription ||
        !data.primaryMot ||
        !user?.distributor.id
      ) {
        throw new Error('Please fill in all required fields');
      }
      return {
        policy_id: policyId || undefined,
        distributor_id: String(user.distributor.id),
        etd: moment(data.etd).locale('en').format('YYYY-MM-DD'),
        eta: data.eta
          ? moment(data.eta).locale('en').format('YYYY-MM-DD')
          : undefined,
        commodity_value: data.cargoValue,
        commodity_currency_code: data.cargoCurrency,
        vessel_name: data.vesselName || undefined,
        transport_mode_code: data.primaryMot,
        origin_port_code: originPortCode,
        destination_port_code: destinationPortCode,

        origin_place_provider_details: originPlace,
        destination_place_provider_details: destinationPlace,
        loading_place_provider_details: loadingPlace || undefined,
        loading_transport_mode_code: data.placeOfLoading
          ? data.placeOfLoadingMot
          : undefined,
        delivery_place_provider_details: deliveryPlace || undefined,
        delivery_transport_mode_code: data.placeOfDelivery
          ? data.placeOfDeliveryMot
          : undefined,
        external_reference: data.bookingReference || undefined,
        commodity_external_description: data.cargoDescription.trim(),
        goods_marks: data.marksAndNumbers?.trim() || undefined,
        cargo_owner: getAssuredDetails(),
        letter_of_credit: data.letterOfCredit || null,
      };
    };

    const getUpdatePayload = (): CertificateUpdatePayload => {
      invariant(policyId, 'policyId is required for update');
      invariant(user?.distributor.id, 'Distributor ID is required for update');

      return {
        ...getJourneyUpdatePayload(form),

        policy_id: policyId,
        distributor_id: String(user?.distributor.id),
        etd: getUpdatedValue(data.etd, formMeta, 'etd'),
        eta: getUpdatedValue(data.eta, formMeta, 'eta'),
        commodity_value: getUpdatedValue(
          data.cargoValue,
          formMeta,
          'cargoValue',
        ),
        // As the currency picker is not assigned a field value, we need to check if the default value has changed manually (until a new component is created)
        commodity_currency_code:
          form.options?.defaultValues?.cargoCurrency !== data.cargoCurrency
            ? data.cargoCurrency
            : undefined,
        vessel_name: getUpdatedValue(data.vesselName, formMeta, 'vesselName'),
        external_reference: getUpdatedValue(
          data.bookingReference,
          formMeta,
          'bookingReference',
        ),
        commodity_external_description: getUpdatedValue(
          data.cargoDescription,
          formMeta,
          'cargoDescription',
        ),
        goods_marks: getUpdatedValue(
          data.marksAndNumbers,
          formMeta,
          'marksAndNumbers',
        ),
        cargo_owner: getAssuredDetails(),
        letter_of_credit: getUpdatedValue(
          data.letterOfCredit,
          formMeta,
          'letterOfCredit',
        ),
      };
    };

    return isUpdate ? getUpdatePayload() : getCreationPayload();
  };

  return {
    getPayload,
    certificateId,
    policyId,
    locationHandleBlur,
    isUpdate,
    isDuplicate,
  };
};
