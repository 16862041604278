import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { fsIdentifyUser } from '../../frameworks/fullstory';
import { type User } from '../../model/User';
import {
  authenticateCredentials,
  isRefreshTokenActive,
  type LoginCredentials,
  revokeAuth,
  sendPasswordResetEmail,
} from '../../network/apis/auth/auth';
import { type AuthErrorType } from '../../network/apis/auth/types';
import { type ErrorResponse } from '../../network/apis/types';
import {
  getUserDetails,
  getUserSettings,
} from '../../network/apis/users/users';
import { queryClient } from '../../providers/ReactQueryProvider';
import { PlatformError } from '../../router/PlatformError/PlatformError';
import { isTestEnv } from '../../utils/env';
import { useTradeFlowLogoutLogic } from './tradeflow/useTradeFlowLogoutLogic';

export type AuthState = {
  user?: User;
  authenticated?: boolean;
  error?: boolean;
  isLoggingOut?: boolean;
};

export type AuthActions = {
  logout: () => void;
  login?: (
    credentials: LoginCredentials,
    redirectToPath?: string,
  ) => Promise<void>;
  resetPassword?: (email: string) => Promise<void>;
};

type AuthContext = AuthState &
  AuthActions & {
    setAuthState: Dispatch<SetStateAction<AuthState>>;
    redirectToOnLogin?: string;
    setRedirectToOnLogin: (path: string) => void;
  };

/**
 * An HOC component to wrap children with the platform's authentication state.
 * Exposes current user's details and auth state, and a set of auth actions to
 * be used across the various platform pages and flows.
 *
 * This context is meant to sit in a top level so the authentication state is
 * accessible everywhere.
 */
export const AuthContext = createContext<AuthContext>({
  setAuthState: () => {},
  setRedirectToOnLogin: () => {},
  logout: () => {},
});

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [authState, setAuthState] = useState<AuthState>({
    authenticated: isRefreshTokenActive(),
  });
  const [redirectToOnLogin, setRedirectToOnLogin] = useState<string>('/');

  const { user, authenticated, error, isLoggingOut } = authState;
  const loading = authenticated && !user;

  const login = useCallback(async (credentials: LoginCredentials) => {
    await authenticateCredentials(credentials);

    setAuthState({ authenticated: true });
  }, []);

  const resetPassword = useCallback(async (email: string) => {
    await sendPasswordResetEmail(email);
  }, []);

  const logout = useCallback(async () => {
    setAuthState({ ...authState, isLoggingOut: true });

    await revokeAuth(authenticated);

    setRedirectToOnLogin('/');
    setAuthState({ authenticated: false, isLoggingOut: false });
    queryClient.clear();
  }, [authenticated, authState]);

  const fetchUserDetails = useCallback(async (): Promise<User> => {
    const user = await getUserDetails();
    user.settings = await getUserSettings();

    return user;
  }, []);

  const { shouldLogOutFromTradeFlow } = useTradeFlowLogoutLogic({
    logout,
  });

  useEffect(() => {
    if (shouldLogOutFromTradeFlow || isLoggingOut) {
      return;
    }

    const path = window.location.pathname;
    const isDTC = path.includes('flexport');

    if (authenticated && !user) {
      fetchUserDetails()
        .then((user) => {
          setAuthState({ authenticated: true, user });
          !isDTC && !isTestEnv && fsIdentifyUser(user);
        })
        .catch((e) => {
          const { response } = e as ErrorResponse<AuthErrorType>;
          const { error_type } = response.data;

          if (error_type === 'RefreshTokenExpiredException') {
            setAuthState({ authenticated: false });
          } else {
            setAuthState({ authenticated: false, error: true });
          }
        });
    }
  }, [
    authenticated,
    fetchUserDetails,
    user,
    isLoggingOut,
    shouldLogOutFromTradeFlow,
  ]);

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        setAuthState,
        login,
        resetPassword,
        logout,
        redirectToOnLogin,
        setRedirectToOnLogin,
      }}
    >
      {error && <PlatformError />}
      {!loading && !error && children}
    </AuthContext.Provider>
  );
};
