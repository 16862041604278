import { useEffect } from 'react';
import { Badge, Tooltip, Typography } from '@breeze-ai/ui-library';
import classnames from 'classnames';
import invariant from 'tiny-invariant';

import { useFeatureToggle, useUser } from '../../../context/auth/auth-hooks';
import { isPlace } from '../../../model/Place';
import { type Quote } from '../../../model/Quote';
import { trackPageView } from '../../../utils/snowplow/utils';
import { COVERAGE_PACKAGES } from '../../constants';
import { WizardSteps } from '../../policy-wizard/constants';
import { usePolicyWizardContext } from '../../policy-wizard/hooks/context-hooks';
import {
  getPlaceDisplayValue,
  getPlaceModelDisplayValue,
} from '../../utils/places';
import { getPortShortDisplayValue } from '../../utils/ports';
import { JourneySummary } from '../JourneySummary/JourneySummary';
import styles from './PolicySummary.module.scss';
import { usePolicySummaryItems } from './use-policy-summary-items';

type PolicySummaryProps = {
  quote: Quote;
};

export const PolicySummary = ({
  quote,
  quote: {
    loading_place,
    primary_transport_mode_code,
    origin_place,
    origin_port,
    destination_place,
    destination_port,
    delivery_place,
    coverage_package,
  },
}: PolicySummaryProps) => {
  invariant(
    primary_transport_mode_code,
    'primary_transport_mode_code is required',
  );
  const showCoveragePackage = useFeatureToggle(
    'enable_coverage_package_selection',
  );
  const enableQuotesV2 = useFeatureToggle('enable_quotes_v2');

  const origin = origin_port || origin_place;

  const destination = destination_port || destination_place;

  const { mainItems, footerItems } = usePolicySummaryItems(quote);
  const user = useUser();
  const { step } = usePolicyWizardContext();

  useEffect(() => {
    step === WizardSteps.SHIPMENT_INFORMATION &&
      trackPageView(
        {
          eventFeature: 'policy',
          eventSubfeature: 'form',
          eventAction: 'page_view',
          eventTrigger: 'issue_a_policy',
          quoteId: quote?.id,
          customerId: quote?.customer?.id,
        },
        user,
      );
  }, [user, step, quote]);

  if (!origin || !destination) {
    return null;
  }

  return (
    <div
      className={classnames(styles.wrapper, 'bg-cards-summary-details-bg')}
      data-testid="policy-summary"
    >
      {mainItems.map((list, i) => (
        <div key={i} className={styles.itemsList}>
          {list.map(({ title, value, hidden, testId }, j) => {
            if (hidden) return null;
            return (
              <div key={j} className={styles.item} data-testid={testId}>
                {title}
                {value}
              </div>
            );
          })}
        </div>
      ))}
      {showCoveragePackage && coverage_package && (
        <Badge
          outlined
          className={styles.coveragePackage}
          data-testid="coverage-type-badge"
        >
          {COVERAGE_PACKAGES[coverage_package].badgeLabel}
        </Badge>
      )}
      {enableQuotesV2 ? (
        <div className="grid grid-cols-2 gap-y-5 my-3.5 mb-5">
          {loading_place && (
            <>
              <span className="text-sm text-system-grey-200">
                Place of loading
              </span>
              <span className="justify-self-end text-base">
                {getPlaceModelDisplayValue(loading_place)}
              </span>
            </>
          )}
          <span className="text-sm text-system-grey-200">Origin</span>
          <span className="justify-self-end text-base">
            {isPlace(origin)
              ? getPlaceDisplayValue(origin)
              : getPortShortDisplayValue(origin)}
          </span>
          <span className="text-sm text-system-grey-200">Destination</span>
          <span className="justify-self-end text-base">
            {isPlace(destination)
              ? getPlaceDisplayValue(destination)
              : getPortShortDisplayValue(destination)}
          </span>
          {delivery_place && (
            <>
              <span className="text-sm text-system-grey-200">
                Place of Discharge
              </span>
              <span className="justify-self-end text-base">
                {getPlaceModelDisplayValue(delivery_place)}
              </span>
            </>
          )}
        </div>
      ) : (
        <JourneySummary
          stops={[origin, destination]}
          conveyances={[primary_transport_mode_code]}
          className={styles.journeySummary}
          variant="dark"
        />
      )}
      <div className={styles.footerGrid}>
        {footerItems.map(({ title, value, hidden, testId }, i) =>
          hidden || !value ? null : (
            <div key={i} className={styles.item} data-testid={testId}>
              <Tooltip content={`${value}`} placement="bottom">
                <Typography
                  level="h3"
                  noBold
                  ellipsis
                  className={styles.value}
                  data-testid="value"
                >
                  {value}
                </Typography>
              </Tooltip>
              <Typography level="subtext" ellipsis data-testid="title">
                {title}
              </Typography>
            </div>
          ),
        )}
      </div>
    </div>
  );
};
