import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ErrorMessage } from '@breeze-ai/ui-library';
import { Spinner } from '@breezeai-frontend/cargo-ui';
import axios from 'axios';
import { stringify } from 'query-string';

import { AuthContext } from '../../context/auth/AuthContext';
import { type User } from '../../model/User';
import {
  GCID,
  REDIRECT_TO_PATH,
  setAuthTokens,
} from '../../network/apis/auth/auth';
import {
  getCookie,
  removeCookie,
  setCookie,
} from '../../network/apis/auth/cookies';
import { type AuthTokenResponse } from '../../network/apis/auth/types';
import { get } from '../../network/apis/utils';
import { bffServiceUrl } from '../../network/netconfig';

export type GetAuthTradeflowLoginCallbackResponse = AuthTokenResponse & {
  user: User;
};

const missingQueryParamMessage = (paramName: string | string[]) =>
  `Unexpected callback URL, missing parameters: ${paramName}`;

export function STSLoginCallback() {
  const [searchParams] = useSearchParams();

  const { setRedirectToOnLogin, setAuthState } = useContext(AuthContext);

  const [isError, setIsError] = useState<boolean>();
  const [isUnauthorized, setIsUnauthorized] = useState<boolean>();

  useEffect(() => {
    const state = searchParams.get('state');
    const code = searchParams.get('code');

    if (!state) {
      throw missingQueryParamMessage('state');
    }

    if (!code) {
      throw missingQueryParamMessage('code');
    }

    const stateUrl = new URL(decodeURIComponent(state));
    const gcId = stateUrl.searchParams.get('gcid');

    if (!gcId) {
      throw missingQueryParamMessage('gcid');
    }

    const { protocol, host } = window.location;

    const params = stringify({
      gcid: gcId,
      code,
      redirect_uri: `${protocol}//${host}/login/callback`,
    });

    get<GetAuthTradeflowLoginCallbackResponse>(
      `${bffServiceUrl}/auth/tradeflow/login/callback?${params}`,
      undefined,
      { auth: false },
    )
      .then((data) => {
        const { id_token, refresh_token, expires_at } = data;

        // Redirect to the path that was saved in the cookie before the login
        const redirectToPath = getCookie(REDIRECT_TO_PATH);
        if (redirectToPath) {
          setRedirectToOnLogin(redirectToPath);
          removeCookie(REDIRECT_TO_PATH);
        }

        setCookie(GCID, gcId);
        setAuthTokens({ id_token, refresh_token, expires_at });
        setAuthState({ authenticated: true });
      })
      .catch((e) => {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 401) {
            setIsUnauthorized(true);

            return;
          }
        }
        if (e instanceof Error) {
          setIsError(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isError) {
    return <ErrorMessage />;
  }

  if (isUnauthorized) {
    return (
      <ErrorMessage subtitle="Make sure your user is authorized with this GCID." />
    );
  }

  return <Spinner className="self-center" />;
}
